.body-class-1 {
  scroll-behavior: smooth;
}

.main-img {
  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;

    h1 {
      margin: 0;
      font-size: 4.375rem;
      color: $white;
      span {
        padding: 0.375rem 0.875rem;
        display: inline-block;
      }
    }
  }
}
.bd-heading-1 {
  .bd-example {
    margin-top: 1rem;
    &:first-child {
      margin-top: 0rem;
    }
  }
}

.bd-cheatsheet {
  margin-top: -2rem;
  section {
    article {
      .card {
        &.iq-document-card {
          padding: $spacer * 1.5;
          .tab-content {
            pre {
              &.language-markup {
                margin: unset;
              }
            }
          }
        }
      }
    }
  }
}

.bd-aside {
  &.card {
    &.iq-document-card {
      padding: $spacer * 1.5;
      margin-top: -2rem;
    }
  }
}

/* Table of contents */
.bd-aside {
  a {
    padding: 0.25rem 0.5rem;
    margin-top: 0.125rem;
    margin-left: 0.25rem;
    color: rgba($body-color, 0.95);
    text-decoration: none;
    &:hover,
    &:focus {
      background-color: rgba($primary, 0.1);
    }
  }
  .active {
    font-weight: 600;
    background-color: rgba($primary, 0.1);
    color: var(--#{$variable-prefix}primary);
  }
  .btn {
    padding: 0.25rem 0.5rem;
    font-weight: 600;
    color: $body-color;
    border: 0;
    .right-icon {
      transition: transform 0.35s ease;
    }
    &:hover,
    &:focus {
      background-color: rgba($primary, 0.1);
      color: var(--#{$variable-prefix}primary);
    }
  }
  .btn[aria-expanded="true"] {
    .right-icon {
      transform: rotate(90deg);
    }
  }
}

.scrollspy-example {
  position: relative;
  height: 12.5rem;
  margin-top: 0.5rem;
  overflow: auto;
}

[id="modal"] .bd-example .btn,
[id="buttons"] .bd-example .btn,
[id="tooltips"] .bd-example .btn,
[id="popovers"] .bd-example .btn,
[id="dropdowns"] .bd-example .btn-group,
[id="dropdowns"] .bd-example .dropdown,
[id="dropdowns"] .bd-example .dropup,
[id="dropdowns"] .bd-example .dropend,
[id="dropdowns"] .bd-example .dropstart {
  // margin: 0 1rem 1rem 0;
  margin-top: 0.5rem;
}

/* Layout */
@include media-breakpoint-up(xl) {
  .body-class-1 {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 4fr 1fr;
    grid-template-rows: auto;
  }

  .bd-aside {
    &.sticky-xl-top {
      top: 2rem !important;
    }
  }

  .bd-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    grid-column: 1 / span 3;
  }

  .sticky-xl-top {
    z-index: 2;
  }

  .bd-cheatsheet,
  .bd-cheatsheet section,
  .bd-cheatsheet article {
    display: grid;
    gap: $grid-gutter-width;
    grid-template-columns: 3fr 1fr;
    grid-column: span 2 / span 2;
    grid-template-rows: auto;
  }

  .bd-aside {
    grid-area: 1 / 1;
    scroll-margin-top: 4rem;
  }

  .bd-cheatsheet section,
  .bd-cheatsheet section > .iq-side-content {
    top: 2rem;
    scroll-margin-top: 0;
  }

  .bd-cheatsheet {
    // grid-area: 1 / 2;
    article,
    .bd-heading {
      top: calc(7.2rem + #{$grid-gutter-width});
      scroll-margin-top: calc(7.8rem + #{$grid-gutter-width});
    }
    .bd-heading {
      z-index: 1;
      order: 2;
      .card {
        margin-bottom: 0;
      }
    }
    article {
      .iq-document-card {
        margin-bottom: unset;
        &.iq-doc-head {
          margin-top: -6.8rem;
        }
      }
    }
  }
}
//***********************************
// off canvas
//*********************************//

@include media-breakpoint-between(md, xl) {
  .mobile-offcanvas {
    width: calc(100% - 60%) !important;
  }
}

@include media-breakpoint-down(md) {
  .main-img {
    .container {
      h1 {
        font-size: 3.125rem;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .header {
    .container {
      max-width: unset !important;
    }
  }

  aside {
    &.mobile-offcanvas {
      margin-top: unset !important;
      .offcanvas-header {
        display: block;
      }
    }
  }
  .body-class-1 {
    padding: unset;
    &.container {
      max-width: unset;
    }
  }

  #content {
    .iq-side-content {
      .card {
        margin-top: 1.25rem;
      }
    }
  }

  .mobile-offcanvas {
    visibility: hidden;
    transform: translateX(-100%);
    border-radius: 0;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 1200;
    width: calc(100% - 25%);
    overflow-y: scroll;
    overflow-x: hidden;
    transition: visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
    background: #ffffffd6;
    backdrop-filter: blur(0.5rem);
  }

  .mobile-offcanvas {
    &.show {
      visibility: visible;
      transform: translateY(0);
    }
    .container,
    .container-fluid {
      display: block;
    }
  }
}

@include media-breakpoint-up(xl) {
  .bd-aside.card.iq-document-card,
  .bd-cheatsheet {
    margin-top: -3rem;
  }
  aside {
    &.mobile-offcanvas {
      .offcanvas-header {
        display: none;
      }
    }
  }
  .bd-cheatsheet {
    section {
      .iq-side-content {
        grid-column-end: 3;
        .card {
          margin-bottom: 0;
        }
      }
    }
  }
}

body.offcanvas-active {
  overflow: hidden;
}
body.uikit {
  .btn-download {
    display: none;
  }
}

.uisheet {
  &.screen-darken {
    height: 100%;
    width: 0%;
    z-index: 30;
    position: fixed;
    top: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    background-color: rgba($gray-900, 0.6);
    transition: opacity 0.2s linear, visibility 0.2s, width 2s ease-in;

    &.active {
      z-index: 10;
      transition: opacity 0.3s ease, width 0s;
      opacity: 1;
      width: 100%;
      visibility: visible;
    }
  }
}

//Back-To-Top

#back-to-top {
  display: inline !important;
  .top {
    bottom: 1.563rem;
    right: 1.563rem;
    z-index: 999;
  }
}

.middle {
  display: inline !important;
  .mid-menu {
    position: fixed;
    top: 9.375rem;
    left: 0rem;
    z-index: 999;
    text-align: center;
    cursor: pointer;
    background: var(--#{$variable-prefix}primary);
    color: $white;
    padding: 0.2rem;
    border-radius: 0 1rem 1rem 0;
  }
}
.top-1 {
  top: $spacer;
}
.elem-list {
  max-height: calc(80vh + 4rem);
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 10px;
  }
  /* Scroll Track */
  &::-webkit-scrollbar-track {
    background: $body-bg;
    border-radius: 10px;
  }
  /* Scroll Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba($dark, 0.2);
    border-radius: 10px;
    transition: all 400ms ease;
    cursor: pointer;
  }
  /* Scroll Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba($dark, 0.2);
    border-radius: 10px;
    transition: all 400ms ease;
    cursor: pointer;
  }
}

// Modify A

.satuarate-0 {
  filter: saturate(0);
}

.nav-pills {
  // min-width: 20rem;
  max-width: 20rem;
}

@include media-breakpoint-down(md) {
  .nav-iconly .nav-link {
    min-width: auto;
  }
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

.threeColTable {
  width: 33.33%;
  max-width: 33.33%;
  overflow: hidden;
}

.lefletArea {
  .leaflet-container {
    position: initial !important;
    min-height: 250px;
  }
}

.tableSearch {
  min-width: 13rem;
}

.whatsapp-preview,
.mobile-preview {
  span {
    z-index: 3;
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 0;
      z-index: 1;
      box-shadow: $box-shadow;
      border-style: solid;
      border-color: transparent $white;

      border-width: 7px 11px 7px 0;
      top: 15px;
      left: -11px;
      margin-top: -7px;
    }
  }
}

.whatsapp-preview {
  span {
    background-color: #fff;
    &::after {
      border-color: transparent rgba(255, 255, 255, 1);
    }
  }
}

.mobile-preview {
  img.mobile {
    z-index: 2;
    max-width: 30rem;
  }
}

.z-0 {
  z-index: 0;
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}

.bg-mobile-priview {
  background-image: url(../../../images/mobile_frame.png);
  background-repeat: no-repeat;
}

// Modify B
.object-fit-cover {
  object-fit: cover;
}
.add_floating__button {
  z-index: 1000;
  right: 2rem;
  bottom: 3rem;
  box-shadow: -0.2rem 0.4rem 1.4rem 0 rgba($dark, 0.4) !important;
  &:focus {
    border: 2px solid $white !important;
  }
}

.modaltest:before {
  position: absolute;
  background-color: rgba($dark, 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
}
// Modify B Content studio
.transition {
  transition: $transition-base;
}
@mixin lineClamp($lineClampNum: 1) {
  -webkit-line-clamp: $lineClampNum;
  -webkit-box-orient: vertical;
  line-clamp: $lineClampNum;
  box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
.contentCard__img {
  height: 10rem;
}
// lineClamp
.textSingleLine {
  @include lineClamp;
}

.textDoubleLine {
  @include lineClamp(2);
}

.textThreeLine {
  @include lineClamp(3);
}

.textFourLine {
  @include lineClamp(4);
}
.cursor-pointer {
  cursor: pointer;
}
.content-studio__create {
  &__form {
    width: 0;
    overflow: hidden;
    translate: 4rem 0 0 0;
    opacity: 0;
    visibility: hidden;
    transition: $transition-base;
    .form-control {
      border-color: rgba($primary, 0.1);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
    .btn-icon.btn-sm .btn-inner svg {
      width: 0.7rem;
      height: 0.7rem;
    }
  }

  // on show form
  &--Expanded {
    .content-studio__create__form {
      width: 22rem;
      opacity: 1;
      visibility: visible;
      translate: 0 0 0 0;
      .form-control {
        padding-right: 3.2rem;
        padding-left: 1rem;
      }
    }
  }
}

.logo-title svg {
  height: 1.7rem;
  width: auto;
}

.find-file-modal {
  .modal-content {
    background-color: var(--bs-body-bg);
  }
  .find-file-modal__left {
    // width: 22rem;
    min-width: 22rem;
  }
}

.fileselect-check-box {
  z-index: 2;
  top: -0.8rem;
  right: -0.8rem;
  .check-mark {
    margin-top: 0.2rem;
    margin-right: 0.3rem;
  }
  .form-check {
    background-color: var(--bs-gray-200);
  }
  .form-check-input {
    width: 100%;
    z-index: 2;
    position: relative;
    height: 100%;
    border-radius: 50%;
    margin: 0;
    border: none;
    background-color: transparent;
    background-size: 1.5rem;
    background-position: bottom 0.8rem left 0.8rem;
  }
}

.reactEmailEditorPrrnt {
  & > div {
    min-height: 100% !important;
  }
}
.sms-suggestion {
  &--floatingForm {
    .form-control {
      padding-right: 5rem !important;
    }
  }
  &__button {
    right: $input-padding-x;
    top: $input-padding-y;
  }
  &__tabNav {
    width: 12rem;
    max-height: 30rem;
    &__icon {
      top: 0.4rem;
      right: 0.8rem;
    }
    .nav-link {
      padding: 0.5rem 2rem 0.5rem 0.7rem;
      &.active {
        .sms-suggestion__tabNav__icon {
          right: 0.3rem;
        }
      }
    }
  }
}

.w-fit-content {
  width: fit-content;
}

.scroll-thin {
  min-width: 12rem;
}

// file-preview-modal
.content-previewModal {
  &__left {
    padding-top: 7rem;
  }
  &__cover {
    height: 10rem;
    .overlay {
      z-index: 1;
    }
  }
  &__container {
    // min-height: 10rem;
    z-index: 2;
    * {
      width: 100% !important;
    }
    iframe {
    }
  }
}

.chat-body{
  &--reply-task-details{ min-height: 500px !important; max-height: 500px !important;}
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .border-md-1 {
    // border-width: 1px !important;
    border: 1px solid #eee !important;
  }
}

.animate_spinner {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.text-break-spaces {
  white-space: break-spaces;
}

.graph-gap {
  gap: 6rem;
}

@media (max-width: 991px) {
  .graph-gap {
    gap: 4rem;
  }
}
@media (max-width: 767px) {
  .graph-gap {
    gap: 2rem;
  }
}

.notification_Card {
  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 0.3rem;
    background-color: var(--bs-primary);
    border-radius: 0.3rem 0 0 0.3rem;
  }
}
