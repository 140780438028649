@mixin floating-input-varient(
  $normal-state,
  $active-state,
  $padding-y,
  $padding-x,
  $height: null
) {
  > .form-control,
  > .form-select {
    height: $height;
  }
  > label {
    height: unset;
    transform: $normal-state;
    padding: $padding-y $padding-x;
    //A-Custom
    width: auto;
    // margin-left: 0.8rem;
    margin-bottom: 0;
    font-size: 0.8rem;
  }
  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-select {
    ~ label {
      transform: $active-state;
    }
  }
}
